import { createSlice } from "@reduxjs/toolkit";
import { FetchingTypes } from "../interfaces/types";

export interface initialStateDashboardProps {
    fetchingStockData: FetchingTypes;
    stockData?: any;
}

const initialState: initialStateDashboardProps = {
    fetchingStockData: "unknown",
    stockData: undefined
}

const dashboardSlice = createSlice({
    name: 'dashboard',
    initialState,
    reducers: {
        getStockDataRequest: (state) => {
            state.fetchingStockData = "loading";
        },
        getStockDataSuccess: (state, action) => {
            state.fetchingStockData = "success";
            state.stockData = action.payload;
        },
        getStockDataFailure: (state) => {
            state.fetchingStockData = "failure";
        },
    }
});

export const { getStockDataRequest, getStockDataSuccess, getStockDataFailure } = dashboardSlice.actions;

export default dashboardSlice.reducer;