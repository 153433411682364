import React from "react";
import { RouteObject } from "../../interfaces/navigation";

const EntryPoint = React.lazy(() => import("../../containers/DashboardExecutive/EntryPointContainer"));

export const dashboardExecutiveRoutes: RouteObject[] = [
    {
        title: "dashboardExecutive",
        path: "/dashboardExecutive",
        component: <EntryPoint />,
        protected: true
    }
];