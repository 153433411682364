import React from "react";
import { RouteObject } from "../../interfaces/navigation";

const EntryPoint = React.lazy(() => import("../../containers/DashboardStock/EntryPointContainer"));

export const dashboardStockRoutes: RouteObject[] = [
    {
        title: "dashboardStock",
        path: "/dashboardStock",
        component: <EntryPoint />,
        protected: true
    }
];