import { memo } from 'react';
import styles from './publicationDetail.module.scss';
import { Link} from "react-router-dom";
import { PublicationDetailProps } from '../../interfaces/components';
import { addPointsInText, capitalize } from '../../helpers/formatters';
import Slider from '../Slider';
import Confort from '../Confort';
import Tag from '../Tag/Tag';
import Caracteristic from '../Caracteristic';
import Certified from '../Certified/Certified';
import InformationLoader from './Loader/Information/InformationLoader';
import CaracteristicsLoader from './Loader/Caracteristics/CaracteristicsLoader';
import ConfortsLoader from './Loader/Confort/ConfortsLoader';
import TagsLoader from './Loader/Tags/TagsLoader';
import LinzeAiItemLoader from './Loader/LinzeAiItem/LinzeAiItemLoader';
import { images } from '../../constants/images';
import { QRCodeSVG } from "qrcode.react";

const PublicationDetail = ({
    publication,
    loading = false,
    children
}: PublicationDetailProps) => {
    console.log("publication",publication)
    return (
        <div className={styles.container}>
            {
                <>
                    <div className={styles.grid}>
                        <div className={styles.slider}>
                            {
                                publication?.certified &&
                                <Certified className={styles.certified} />
                            }
                            {
                                <Slider
                                    type="thumbs"
                                    options={
                                        publication?.pictures && publication?.pictures.length > 0
                                        ?
                                            publication?.pictures.map((item: any, index: number) => (
                                                <img loading="lazy" key={index} src={item?.path ?? item?.previewURL ?? item?.uri ?? item?.url} alt="Image" />
                                            ))
                                        :
                                            [1].map((_: any, index: number) => (
                                                <img loading="lazy" key={index} src={images.noImagePublication} alt="Image" />
                                            ))
                                    }
                                    pagination
                                    loading={loading}
                                />
                            }
                        </div>
                        {
                            loading
                            ?   <div className={styles.information}>
                                    <InformationLoader />
                                </div>
                            :   <div className={styles.information}>
                                    <p>
                                        <span className={styles.name}>{publication?.title}</span>
                                        {
                                            publication?.version &&
                                            <span className={styles.description}>{publication?.version}</span>
                                        }
                                        <span className={styles.description}>{publication?.year} - {addPointsInText(publication?.mileage)}km</span>
                                        <span className={styles.actualPrice}>${addPointsInText(publication?.price)}</span>
                                    </p>
                                    <QRCodeSVG
                                        value={publication?.id ?? ""}
                                        size={90}
                                        bgColor={"#ffffff"}
                                        fgColor={"rgba(159, 133, 236, 0.7)"}
                                        color="rgba(159, 133, 236, 0.7)"
                                        level={"L"}
                                        includeMargin={false}
                                    />
                                    {children}
                                </div>
                        }
                        {
                            loading
                                ?   <div className={`${styles.item} ${styles.linzeAiItem}`}>
                                        <LinzeAiItemLoader />
                                    </div>
                                :   publication?.chatgpt_description?.features &&
                                    <div className={`${styles.item} ${styles.linzeAiItem}`}>
                                        <p className={styles.title}>Descripción</p>
                                        <div className={styles.content}>
                                            {
                                                publication?.chatgpt_description?.features.map((item: any, index: number) => (
                                                    <p key={index}>
                                                        <span>●</span>
                                                        <span>{item}</span>
                                                    </p>
                                                ))
                                            }
                                        </div>
                                    </div>
                                
                        }
                        {
                            loading
                                ?   <div className={`${styles.item} ${styles.linzeAiItem}`}>
                                        <LinzeAiItemLoader />
                                    </div>
                                :   publication?.chatgpt_description?.advantages &&
                                    <div className={`${styles.item} ${styles.linzeAiItem}`}>
                                        <p className={styles.title}>Ventajas</p>
                                        <div className={styles.content}>
                                            {
                                                publication?.chatgpt_description?.advantages.map((item: any, index: number) => (
                                                    <p key={index}>
                                                        <span>●</span>
                                                        <span>{item}</span>
                                                    </p>
                                                ))
                                            }
                                        </div>
                                    </div>
                                
                        }
                        {
                            loading
                                ?   <div className={`${styles.item} ${styles.linzeAiItem}`}>
                                        <LinzeAiItemLoader />
                                    </div>
                                :   publication?.chatgpt_description?.disadvantages &&
                                    <div className={`${styles.item} ${styles.linzeAiItem}`}>
                                        <p className={styles.title}>Consideraciones</p>
                                        <div className={styles.content}>
                                            {
                                                publication?.chatgpt_description?.disadvantages.map((item: any, index: number) => (
                                                    <p key={index}>
                                                        <span>●</span>
                                                        <span>{item}</span>
                                                    </p>
                                                ))
                                            }
                                        </div>
                                    </div>
                                
                        }
                        {
                            !loading &&
                            (publication?.chatgpt_description?.features || publication?.chatgpt_description?.advantages || publication?.chatgpt_description?.disadvantages) &&
                            <div className={styles.linzeAiMessage}>
                                <p>
                                    <span>La Información proporcionada podría no ser precisa en relación al modelo del vehículo.</span>
                                    <span>Generada por ChatGPT</span>
                                </p>
                            </div>
                        }
                        {
                            loading
                                ?   <div className={`${styles.item} ${styles.caracteristics}`}>
                                        <CaracteristicsLoader />
                                    </div>
                                :   <div className={`${styles.item} ${styles.caracteristics}`}>
                                        <p className={styles.title}>Caracteristicas</p>
                                        <div className={styles.content}>
                                            {
                                                publication?.caracteristics?.type && <Caracteristic type="type" value={publication?.caracteristics?.type} />
                                            }
                                            {
                                                publication?.caracteristics?.color && <Caracteristic type="color" value={publication?.caracteristics?.color} />
                                            }
                                            {
                                                publication?.caracteristics?.transmission && <Caracteristic type="transmission" value={publication?.caracteristics?.transmission} />
                                            }
                                            {
                                                publication?.caracteristics?.fuel && <Caracteristic type="fuel" value={publication?.caracteristics?.fuel} />
                                            }
                                        </div>
                                    </div>
                        }
                        {
                            loading
                                ?   <div className={`${styles.item} ${styles.conforts}`}>
                                        <ConfortsLoader />
                                    </div>
                                :   publication?.confort && 
                                    publication?.confort.some((item: any) => item[1] === true) && 
                                    <div className={`${styles.item} ${styles.conforts}`}>
                                        <p className={styles.title}>{capitalize("confort")}</p>
                                        <div className={styles.content}>
                                            {
                                                publication?.confort && publication?.confort.map((item: any, index: number) => (
                                                    <Confort key={index} item={item} />
                                                ))
                                            }
                                        </div>
                                    </div>
                        }
                        {
                            loading
                                ?   <div className={`${styles.item} ${styles.tags}`}>
                                        <TagsLoader />
                                    </div>
                                :   publication?.tags && publication?.tags.length > 0 && 
                                    <div className={`${styles.item} ${styles.tags}`}>
                                        <p className={styles.title}>{capitalize("etiquetas")}</p>
                                        <div className={styles.content}>
                                            {
                                                publication?.tags.map((item: any, index: number) => (
                                                    <Tag key={index} text={item} />
                                                ))
                                            }
                                        </div>
                                    </div>
                        }
                    </div>
                </>
            }
        </div>
    )
}

export default memo(PublicationDetail);