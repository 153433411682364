import React, { memo, useEffect, useState } from 'react';
import styles from "./sideBar.module.scss";
import { List, ListItemButton, ListItemIcon, ListItemText, Collapse } from '@mui/material';
import { useLocation, useNavigate } from "react-router-dom";
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import ExitToAppOutlinedIcon from '@mui/icons-material/ExitToAppOutlined';
import { tabs } from '../../constants/sidebar';
import { useWindowSize } from "use-hooks";
import { LuBuilding, LuLayoutDashboard } from 'react-icons/lu';
import { RiDashboard2Line } from "react-icons/ri";
import { BsBuildings } from "react-icons/bs";
import { FaUsers } from "react-icons/fa6";
import { FiSettings } from 'react-icons/fi';
import { AiOutlineStock } from "react-icons/ai";
import AssignmentOutlinedIcon from '@mui/icons-material/AssignmentOutlined';
import PaidOutlinedIcon from '@mui/icons-material/PaidOutlined';
import PriceChangeOutlinedIcon from '@mui/icons-material/PriceChangeOutlined';
import SellOutlinedIcon from '@mui/icons-material/SellOutlined';
import { SidebarLayoutProps } from '../../interfaces/components';
import { tabProps } from '../../interfaces/sidebar';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import RuleIcon from '@mui/icons-material/Rule';
import { AiOutlineLineChart } from 'react-icons/ai';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import IntegrationInstructionsIcon from '@mui/icons-material/IntegrationInstructions';
const SideBar = ({
    logout
}: SidebarLayoutProps) => {
    const location = useLocation();
    const navigate = useNavigate();
    const windowSize = useWindowSize();
    const [value, setValue] = useState<any>("dashboard");
    const [subTabs, setSubTabs] = useState<any>({ open: false, id: "" });
    const isInLeads = location.pathname.includes("leads");

    const tabsIcon = (value: string) => {
        let icon;
        switch (value) {
            case "dashboard":
                icon = <LuLayoutDashboard className={styles.icon} />
                break;
            case "dashboardEx":
                icon = <BsBuildings className={styles.icon} />
                break;
            case "dashboardIt":
                icon = <RiDashboard2Line className={styles.icon} />
                break;
            case "dashboardStock":
                icon = <AiOutlineStock className={styles.icon} />
                break;
            case "dashboardExecutive":
                icon = <FaUsers className={styles.icon} />
                break;
            case "dashboardIntegrations":
                icon = <IntegrationInstructionsIcon className={styles.icon} />
                break;
            case "dealerships":
                icon = <LuBuilding className={styles.icon} />
                break;
            case "settings":
                icon = <FiSettings className={styles.icon} />
                break;
            case "terms":
                icon = <AssignmentOutlinedIcon className={styles.icon} />
                break;
            case "additionalCosts":
                icon = <PaidOutlinedIcon className={styles.icon} />
                break;
            case "payments":
                icon = <PriceChangeOutlinedIcon className={styles.icon} />
                break;
            case "publications":
                icon = <SellOutlinedIcon className={styles.icon} />
                break;
            case "review":
                icon = <RuleIcon className={styles.icon} />
                break;
            case "allPublications":
                icon = <FormatListBulletedIcon className={styles.icon} />
                break;
            case "leads":
                icon = <AiOutlineLineChart className={styles.icon} />
                break;
            case "pickupPoints":
                icon = <LocationOnIcon className={styles.icon} />
                break;
            default:
                icon = <PersonOutlineIcon className={styles.icon} />
                break;
        }
        return icon
    }

    const onClickItem = (tab: any) => {
        if (subTabs.open) {
            if (tab.id !== subTabs.id) {
                setSubTabs({ ...subTabs, id: tab.id });
            } else {
                setSubTabs({ ...subTabs, open: !subTabs.open });
            }
        } else {
            setSubTabs({ open: !subTabs.open, id: tab.id });
        }
    }

    const handleChange = (event: React.SyntheticEvent, newValue: string, navigateValue: string) => {
        setValue(newValue);
        navigate(navigateValue);
    };

    const handleLogout = () => {
        logout();
        navigate("/");
    };

    useEffect(() => {
        setValue(location.pathname.split('/')[1]);
    }, [location.pathname]);

    return (
        <div data-isInLeads={isInLeads} className={styles.sidebar}>
            <List component="nav" className={styles.list}>
                {
                    tabs.map((tab: tabProps, index: number) => (
                        <React.Fragment key={tab.id + Math.random()}>
                            <ListItemButton
                                key={tab.id + Math.random()}
                                onClick={(e: any) => tab.children ? onClickItem(tab) : handleChange(e, tab.id, tab.navigate)}
                                className={styles.item}
                                selected={tab.id === value}
                                classes={{
                                    selected: styles.selected
                                }}
                            >
                                <ListItemIcon className={styles.icon}>{tabsIcon(tab.icon)}</ListItemIcon>
                                <ListItemText className={styles.text} primary={tab.label} />
                                {tab.children ? (subTabs.open && tab.id === subTabs.id ? <ExpandLess className={styles.arrow} /> : <ExpandMore className={styles.arrow} />) : null}
                            </ListItemButton>
                            {
                                tab.children &&
                                <Collapse key={tab.id + Math.random()} in={subTabs.open && tab.id === subTabs.id} timeout="auto" unmountOnExit>
                                    <List component="div" disablePadding className={styles.subList}>
                                        {
                                            tab.children.map((tabChild: any, tabIndex: number) => (
                                                <ListItemButton
                                                    key={tabIndex}
                                                    onClick={(e: any) => handleChange(e, tabChild.id, `${tab.navigate}/${tabChild.navigate}`)}
                                                    className={styles.item}
                                                    selected={tabChild.id === value}
                                                    classes={{
                                                        selected: styles.selected
                                                    }}
                                                >
                                                    <ListItemIcon className={styles.icon}>{tabsIcon(tabChild.icon)}</ListItemIcon>
                                                    <ListItemText className={styles.text} primary={tabChild.label} />
                                                </ListItemButton>
                                            ))
                                        }
                                    </List>
                                </Collapse>
                            }
                        </React.Fragment>
                    ))
                }
                <ListItemButton
                    onClick={handleLogout}
                    className={`${styles.item} ${styles.blue}`}
                >
                    <ListItemIcon className={styles.icon}>{<ExitToAppOutlinedIcon />}</ListItemIcon>
                    <ListItemText primary={(windowSize.width > 1200 && !isInLeads) ? "Cerrar sesión" : ""} />
                </ListItemButton>
            </List>
        </div>
    );
}

export default memo(SideBar);