import React from "react";
import { RouteObject } from "../../interfaces/navigation";

const EntryPoint = React.lazy(() => import("../../containers/DashboardIntegrations/EntryPointContainer"));

export const dashboardIntegrationsRoutes: RouteObject[] = [
    {
        title: "dashboardIntegrations",
        path: "/dashboardIntegrations",
        component: <EntryPoint />,
        protected: true
    }
];